











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question521',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        language: 'fr',
      },
      options: [
        {text: '(2R, 5R)', value: '2R5R'},
        {text: '(2S, 5S)', value: '2S5S'},
        {text: '(2R, 5S)', value: '2R5S'},
        {text: '(2S, 5R)', value: '2S5R'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q521.png';
    },
  },
};
